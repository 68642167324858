<template>
  <div class="">
    <span v-if="text">
      {{ textSkills }}
    </span>
    <span v-else v-for="(skill, index) in skills" :key="index">
      <v-chip small
        class="v-chip-light-bg secondary--text mr-2"
        color="secondary"
      >
        {{ skill }}
      </v-chip>
    </span>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    text: {
      default: false,
      type: Boolean,
    },
    skills: {
      default: () => [],
      type: Array,
    },
  },

  setup(props) {
    const { skills } = props;
    const textSkills = computed(() => skills.map((c) => c).join(", "));

    // const isAlphaValidator = (text) => {
    //   var letters = /^[a-zA-Z() ]+$/;
    //   return letters.test(text);
    // }

    return {
      textSkills,
    };
  },
};
</script>

<style></style>
