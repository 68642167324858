<template>
  <div class="mt-5">
    <TitleSection uppercase big :title="t('Project')" v-if="false" />
    <v-data-table
      :headers="headers"
      :items="_projects"
      :search="filterSearchTable"
      :sort-by="['status']"
      :sort-desc="[true]"
      :items-per-page="20"
      show-expand
      :custom-filter="customSearch"
    >
      <template v-slot:top>
        <v-row class="pa-4">
          <v-col cols="12" xs="12" md="4" class="">
            <input-search
              @onInputSearch="onInputSearch"
              :label="t('SearchListProject')"
            />
          </v-col>
          <v-col cols="12" xs="12" offset-md="4" md="4" class="">
            <div class="d-flex justify-end align-center">
              <FilterCategories @onFilterCategories="onFilterCategories" />
              <FilterStatus @onFilterStatus="onFilterStatus" />
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4">
          <p>
            <strong>{{ t("Managers") }}</strong>
          </p>
          {{ getManagersAndOwners(item) }}
        </td>
      </template>

      <!-- Project title -->
      <template #[`item.title`]="{ item }">
        <p>{{ getProjectByLocale(item, "name") }}</p>
      </template>

      <!-- owner -->
      <template #[`item.owner`]="{ item }">
        <p>{{ item.owner.fullname }}</p>
      </template>

      <!-- skills -->
      <template #[`item.categories`]="{ item }">
        <TagCategories :categories="item.categories" />
      </template>

      <!-- status -->
      <template #[`item.status`]="{ item }">
        <ProjectStatus
          :status="item.status"
          :project="item"
          isChangeStatus
          @onChangeProject="onChangeProject"
        />
      </template>

      <!-- action -->
      <template #[`item.actions`]="{ item }">
        <v-btn class="ma-2" color="info" x-small @click="goDetailProject(item)">
          {{ t("Details") }}
        </v-btn>
        <ButtonDownload downloadAll :project="item" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
  reactive,
} from "@vue/composition-api";
import TitleSection from "@/components/general/TitleSection";
import InputSearch from "@/components/general/InputSearch";
import ProjectStatus from "@/components/projects/Status";
import TagCategories from "@/components/projects/TagCategories";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { getI18nLocale } from "@/plugins/i18n";
import { useRouter } from "@core/utils";
import FilterCategories from "./FilterCategories";
import FilterStatus from "./FilterStatus";
import ButtonDownload from "./download/ButtonDownload";

export default {
  props: {
    projects: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    TitleSection,
    InputSearch,
    ProjectStatus,
    TagCategories,
    FilterCategories,
    FilterStatus,
    ButtonDownload,
  },
  setup(props) {
    const { router } = useRouter();
    const { projects } = props;
    const { t } = useUtilsI18n();
    const localei18n = computed(() => getI18nLocale());

    const filterSearchTable = ref(null);
    const filterCategoriesTable = ref([]);
    const filterStatusTable = ref([]);

    const headers = computed(() => {
      return [
        { text: t("Project"), value: "title", width: "40%" },
        { text: t("Owner"), value: "owner", width: "20%" },
        {
          text: t("Discipline"),
          value: "categories",
          filter: (value) => {
            if (!filterCategoriesTable.value.length) return true;
            return value.every((v) => filterCategoriesTable.value.includes(v));
          },
        },
        {
          text: t("Status"),
          value: "status",
          filter: (value) => {
            if (!filterStatusTable.value.length) return true;
            return filterStatusTable.value.includes(value);
          },
        },
        { text: "", value: "actions", width: "20%" },
      ];
    });

    const _projects = ref(projects);

    const customSearch = (value, search, item) => {
      const { owner, es, fr } = item;
      const esName = { esName: es?.name };
      const frName = { frName: fr?.name };

      const searchLocal = { ...owner, ...esName, ...frName };

      return Object.values(searchLocal).some(
        (v) => v && v.toString().toLowerCase().includes(search.toLowerCase())
      );
    };

    const onFilterCategories = ({ categories }) => {
      filterCategoriesTable.value = categories;
    };

    const onFilterStatus = ({ status }) => {
      filterStatusTable.value = status;
    };

    const getManagersAndOwners = (project) => {
      const { owner, managers } = project;

      return new Intl.ListFormat().format(
        [owner, ...managers].map((u) => u.fullname)
      );
    };

    const getProjectByLocale = (project, property) => {
      const projectLocale = project[localei18n.value];
      return projectLocale && (projectLocale[property] || "");
    };

    const goDetailProject = ({ id }) => {
      router.push({ name: "admin.projects.show", params: { id } });
    };

    const onChangeProject = (project) => {
      const projectFindIndex = _projects.value.findIndex(
        (p) => p.id === project.id
      );
      if (projectFindIndex) _projects.value[projectFindIndex] = project;
    };

    const onInputSearch = ({ search }) => {
      filterSearchTable.value = search;
    };

    watch(
      () => props.projects,
      (projects) => (_projects.value = projects)
    );
    onMounted(() => {});

    return {
      _projects,
      headers,
      filterSearchTable,
      filterCategoriesTable,
      customSearch,

      onInputSearch,
      getManagersAndOwners,
      getProjectByLocale,
      goDetailProject,
      onFilterCategories,
      onFilterStatus,
      onChangeProject,
      t,
    };
  },
};
</script>

<style></style>
