<template>
  <div class="d-inline-block">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          color="success"
          x-small
          v-bind="attrs"
          v-on="on"
          :disabled="isDisableButton"
        >
          {{ t("Download") }}
        </v-btn>
      </template>
      <v-list>
        <template v-for="(option, index) in optionDownload">
          <v-list-item
            @click="onDownloadPDF(option)"
            v-if="option.enable"
            :key="index"
          >
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

import api from "@/services";

export default {
  components: {},
  props: {
    project: {
      default: null,
    },
    downloadAll: {
      default: false,
      type: Boolean,
    },
    downloadEs: {
      default: false,
      type: Boolean,
    },
    downloadFr: {
      default: false,
      type: Boolean,
    },
    downloadZip: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const { project, downloadEs, downloadFr, downloadZip, downloadAll } = props;

    const { t } = useUtilsI18n();

    const projectSelectDownload = ref(project);
    const isDownloadProjectZip = ref(false);
    const isDownloadProjectVersion = ref(false);
    const isDownloadProjectAval = ref(false);
    const projectAvals = computed(
      () => projectSelectDownload.value?.avals || []
    );

    const optionDownload = computed(() => [
      { title: t("VersionEs"), value: "es", enable: downloadEs || downloadAll },
      { title: t("VersionFr"), value: "fr", enable: downloadFr || downloadAll },
      {
        title: t("ProjectFiles"),
        value: "all",
        enable: downloadZip || downloadAll,
      },
      {
        title: t("Aval"),
        value: "aval",
        enable: downloadAll && projectAvals.value.length,
      },
    ]);

    const isDisableButton = computed(
      () =>
        isDownloadProjectZip.value ||
        isDownloadProjectVersion.value ||
        isDownloadProjectAval.value
    );

    const onDownloadPDF = ({ value }) => {
      switch (value) {
        case "es":
        case "fr":
          downloadProjectVersion(value);
          break;

        case "all":
          downloadProjectFileAll();

          break;

        case "aval":
          downloadProjectFileAval();

          break;

        default:
          break;
      }
    };

    const downloadProjectVersion = async (version) => {
      const { id: projectId } = projectSelectDownload.value;

      try {
        isDownloadProjectVersion.value = true;

        const { data } = await api.downloadProjectById(projectId, {
          project_version: version,
        });

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `P-${version}-${projectId}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
      } finally {
        isDownloadProjectVersion.value = false;
      }
    };

    const downloadProjectFileAll = async () => {
      const { id: projectId } = projectSelectDownload.value;

      try {
        isDownloadProjectZip.value = true;

        const { data } = await api.downloadProjectById(projectId, {
          all: true,
        });

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `P-${projectId}.zip`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
      } finally {
        isDownloadProjectZip.value = false;
      }
    };

    const downloadProjectFileAval = async () => {
      const { id: projectId } = projectSelectDownload.value;

      try {
        isDownloadProjectAval.value = true;

        const { data } = await api.downloadProjectById(projectId, {
          aval: true,
        });

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Aval-${projectId}.zip`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
      } finally {
        isDownloadProjectAval.value = false;
      }
    };

    watch(
      () => props.project,
      (project) => {
        projectSelectDownload.value = project;
      },
      { deep: true }
    );

    return {
      projectSelectDownload,
      isDisableButton,
      optionDownload,

      onDownloadPDF,
      t,
    };
  },
};
</script>

<style></style>
