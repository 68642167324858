<template>
  <div class="">
    <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="text-capitalize">
           <v-icon small>{{ icons.mdiFilter }}</v-icon>
          {{ t("Discipline") }}
          <v-icon class="pl-2">{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item v-for="(category, index) in categories" :key="index">
            <v-list-item-title>
              <v-checkbox
                v-model="selectCategories"
                multiple
                :label="t(category.key)"
                :value="category.key"
                @change="onFilterCategoriesProject"
              ></v-checkbox>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  reactive,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import categoriesProject from "@/ddbb/projects/categories";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiChevronDown, mdiFilter } from "@mdi/js";

export default {
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();

    const selectCategories = ref([]);
    const categories = reactive([...categoriesProject]);

    const onFilterCategoriesProject = () => {
      emit("onFilterCategories", { categories: selectCategories.value });
    };
    return {
      selectCategories,
      categories,

      icons: {
        mdiChevronDown,
        mdiFilter,
      },

      onFilterCategoriesProject,
      t,
    };
  },
};
</script>

<style></style>
