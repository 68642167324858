var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[(false)?_c('TitleSection',{attrs:{"uppercase":"","big":"","title":_vm.t('Project')}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm._projects,"search":_vm.filterSearchTable,"sort-by":['status'],"sort-desc":[true],"items-per-page":20,"show-expand":"","custom-filter":_vm.customSearch},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"4"}},[_c('input-search',{attrs:{"label":_vm.t('SearchListProject')},on:{"onInputSearch":_vm.onInputSearch}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","offset-md":"4","md":"4"}},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('FilterCategories',{on:{"onFilterCategories":_vm.onFilterCategories}}),_c('FilterStatus',{on:{"onFilterStatus":_vm.onFilterStatus}})],1)])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.t("Managers")))])]),_vm._v(" "+_vm._s(_vm.getManagersAndOwners(item))+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.getProjectByLocale(item, "name")))])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.owner.fullname))])]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_c('TagCategories',{attrs:{"categories":item.categories}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ProjectStatus',{attrs:{"status":item.status,"project":item,"isChangeStatus":""},on:{"onChangeProject":_vm.onChangeProject}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"color":"info","x-small":""},on:{"click":function($event){return _vm.goDetailProject(item)}}},[_vm._v(" "+_vm._s(_vm.t("Details"))+" ")]),_c('ButtonDownload',{attrs:{"downloadAll":"","project":item}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }