<template>
  <v-text-field
    v-model="search"
    :append-icon="icons.mdiMagnify"
    :label="label"
    single-line
    hide-details
    dense
    @input="onInputSearch"
  ></v-text-field>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiMagnify } from "@mdi/js";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  props: {
    label: {
      default: null
    }
  },
  setup(props, { emit }) {
    const { t } = useUtilsI18n();

    const search = ref(null);

    const onInputSearch = () => {
      emit("onInputSearch", { search: search.value })
    } 

    return {
      search,

      icons: {
        mdiMagnify,
      },

      onInputSearch,
      t,
    };
  },
};
</script>

<style></style>
