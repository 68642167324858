<template>
  <TagSkills :text="text" :skills="getCategories" />
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import TagSkills from "@/components/general/TagSkills";
import categoriesProject from "@/ddbb/projects/categories";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  components: {
    TagSkills,
  },
  props: {
    text: {
      default: false,
      type: Boolean,
    },
    categories: {
      default: () => [],
      type: Array,
    },
  },

  setup(props) {
    const { t } = useUtilsI18n();
    const { categories } = props;

    const getCategories = computed(() =>
      categories.filter((c) => !!c).map((c) => t(c))
    );

    return {
      getCategories,
    };
  },
};
</script>

<style></style>
