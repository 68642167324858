<template>
  <div class="">
    <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="text-capitalize">
          <v-icon small>{{ icons.mdiFilter }}</v-icon>
          {{ t("Status") }}
          <v-icon class="pl-2">{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item v-for="(status, index) in status" :key="index">
            <v-list-item-title>
              <v-checkbox
                v-model="selectStatus"
                multiple
                :label="t(status.key)"
                :value="status.key"
                @change="onFilterStatusProject"
              ></v-checkbox>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  reactive,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import statusProject from "@/ddbb/projects/status";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiChevronDown, mdiFilter } from "@mdi/js";

export default {
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();

    const selectStatus = ref([]);
    const status = reactive([...statusProject]);

    const onFilterStatusProject = () => {
      emit("onFilterStatus", { status: selectStatus.value });
    };
    return {
      selectStatus,
      status,

      icons: {
        mdiChevronDown,
        mdiFilter,
      },

      onFilterStatusProject,
      t,
    };
  },
};
</script>

<style></style>
