<template>
  <div class="">
    <div class="" v-if="announcement">
      <v-row>
        <v-col cols="12" xs="12" lg="12">
          <AnnouncementItem onlyTile :announcement="announcement">
            <template #default>
              <div class="d-flex justify-end w-100">
                <v-btn small color="primary" @click="goAnnouncementTasks(announcement)"
                  >{{ $t('See tasks') }}</v-btn
                >
              </div>
            </template>
          </AnnouncementItem>
        </v-col>
      </v-row>
      <!--- Announcement Projects -->
      <ProjectList :projects="announcementProjects" />
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";

import ProjectList from "@/components/projects/ProjectList";
import AnnouncementItem from "@/components/announcenments/AnnouncementItem";
import router from "@/router";
import { useAnnouncement } from '@/composables/announcement/useAnnouncement.js';

export default {
  components: {
    AnnouncementItem,
    ProjectList,
  },

  setup(props, context) {
    const { announcement, announcementProjects, loading, getAnnouncementById } = useAnnouncement(props, context);
    
    const goAnnouncementTasks = ({ id }) => {
      router.push({ name: "admin.announcements.tasks", params: { id } });
    };

    onMounted(() => {
      const { id } = router.currentRoute.params;
      if (id) getAnnouncementById(id, {
        params: {
          projects: true,
        }
      });
    });

    return {
      announcement,
      announcementProjects,
      loading,

      goAnnouncementTasks
    };
  },
};
</script>

<style></style>
